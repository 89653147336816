import React from "react";
import { FaPlus } from "react-icons/fa";

const ProjectAreaOne = () => {
  return (
    <div className='project-section pd-top-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
              <h6 className='sub-title'>Gallery</h6>
              <h2 className='title'>
                Latest <span>Projects</span>
              </h2>
            </div>
          </div>
        </div>
        <nav className='text-center'>
          <div
            className='nav nav-tabs project-nav-tab d-inline-flex mb-5'
            id='nav-tab'
            role='tablist'
          >
            <button
              className='nav-link active'
              id='nav-tabs1-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs1'
              type='button'
              role='tab'
              aria-controls='nav-tabs1'
              aria-selected='true'
            >
              View All
            </button>
            {/* <button
              className='nav-link'
              id='nav-tabs2-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs2'
              type='button'
              role='tab'
              aria-controls='nav-tabs2'
              aria-selected='false'
            >
              Softwate
            </button>
            <button
              className='nav-link'
              id='nav-tabs3-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs3'
              type='button'
              role='tab'
              aria-controls='nav-tabs3'
              aria-selected='false'
            >
              New Project
            </button>
            <button
              className='nav-link'
              id='nav-tabs4-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs4'
              type='button'
              role='tab'
              aria-controls='nav-tabs4'
              aria-selected='false'
            >
              Digitals
            </button>
            <button
              className='nav-link'
              id='nav-tabs5-tab'
              data-bs-toggle='tab'
              data-bs-target='#nav-tabs5'
              type='button'
              role='tab'
              aria-controls='nav-tabs5'
              aria-selected='false'
            >
              Hardware
            </button> */}
          </div>
        </nav>
        <div className='tab-content' id='nav-tabContent'>
          <div
            className='tab-pane fade show active'
            id='nav-tabs1'
            role='tabpanel'
            aria-labelledby='nav-tabs1-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic1.jpg' alt='img' />
                  <p className='details'>
                    <div className='read-more-text' href='#'>
                      Kongoni Coding Bootcamp
                    </div>
                  </p>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic2.jpg' alt='img' />
                  <div className='details'>
                    <p className='read-more-text' href='#'>
                      Coding Hackathon
                    </p>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic3.jpg' alt='img' />
                  <div className='details'>
                    <p className='read-more-text' href='#'>
                      Coding Hackathon
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic4.jpg' alt='img' />
                  <div className='details'>
                    <p className='read-more-text' href='#'>
                      Kid's Robotics
                    </p>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic5.jpg' alt='img' />
                  <div className='details'>
                    <p className='read-more-text' href='#'>
                      Project Presentation
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic6.jpg' alt='img' />
                  <div className='details'>
                    <p className='read-more-text' href='#'>
                      Animation
                    </p>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic7.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Learning Robotics
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/pic8.jpg' alt='img' />
                  <div className='details'>
                    <a className='read-more-text' href='#'>
                      Learning
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className='tab-pane fade'
            id='nav-tabs2'
            role='tabpanel'
            aria-labelledby='nav-tabs2-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs3'
            role='tabpanel'
            aria-labelledby='nav-tabs3-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs4'
            role='tabpanel'
            aria-labelledby='nav-tabs4-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs5'
            role='tabpanel'
            aria-labelledby='nav-tabs5-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
